import { useQuery } from '@apollo/client'
import { user as graphqlUser } from '@graphql'
import { useModal, useWindowSize } from '@hooks'
import { AccountLayout } from '@layouts'
import { StoreSegment } from '@pages/components'
import { CacheUser, ResponseType } from '@types'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import '../account.scss'

interface StoreProps {}

const StorePage: React.FC<StoreProps> = props => {
  const { openModal } = useModal('SellerPaymentMethodWarning')
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { data: userData, loading: userLoading } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const user = userData && userData.res
  const isUserSeller = user?.sellerStatus === 'SELLER'
  const isUserStripeAccountNotSubmitted = user && isUserSeller && !user.stripeAccountSubmitted
  const sellerStatusSegment = isUserSeller ? (
    <StoreSegment.StatusSeller user={user} />
  ) : (
    <StoreSegment.StatusNotSeller />
  )

  useEffect(() => {
    if (isUserStripeAccountNotSubmitted) {
      openModal()
    }
  }, [isUserStripeAccountNotSubmitted])

  if (!userLoading && !user) navigate('/home/')

  return (
    <AccountLayout pageClassName={'account-store'} hideLayout={isMobileScreen}>
      <h6 className={'account-page-title'}>MY STORE</h6>
      {sellerStatusSegment}
    </AccountLayout>
  )
}

export default StorePage
